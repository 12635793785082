import { Button } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../../Navbar1';
import Footer from '../../footer';
import './Form.css'

const Enrollments = () => {
  return (
    <>

    <Navbar />


    <div className='Enroll--container' style={{margin:'auto',textAlign:'center',marginTop:'120px',marginBottom:'120px',}}>

    <h1 style={{textAlign:'center'}} className="home-heading-sizes" id="animate-charcter">Enrollments</h1>
    <div style={{margin:'auto',textAlign:'center',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
    <Link to="/culturalfestform" style={{ textDecoration: "none" }}>
          <Button className="btn-hover color-3" variant="contained" color="primary">
          Cultural Fest Enrollment Form
          </Button>
        </Link>
    </div>

       <div style={{margin:'auto',textAlign:'center',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
       <Link to="/olympiadgamesform" style={{ textDecoration: "none" }}>
          <Button className="btn-hover color-3" variant="contained" color="primary">
          Olympiad Games Form Enrollment Form
          </Button>
        </Link>
       </div>

    </div>

        <Footer />

    </>
  )
}

export default Enrollments;