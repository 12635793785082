import React, { useState } from "react";
import emailjs from "emailjs-com";
import Footer from "../../footer";
import Navbar from "../../Navbar1";
import './Form.css'

const OlympiadGamesForm = () => {
  const [formData, setFormData] = useState({
    childName: "",
    dateOfBirth: "",
    gender: "",
    schoolName: "",
    gradeClass: "",
    parentName: "",
    relationshipToChild: "",
    contactNumber: "",
    emailAddress: "",
    emergencyContactPerson: "",
    emergencyContactNumber: "",
    hasAllergiesOrMedicalConditions: "",
    currentMedications: "",
    activitiesInterestedIn: [],
    requiresLodgingBoarding: false,
    specialRequests: "",
    agreementConfirmed: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const requiredFields = [
      "childName",
      "dateOfBirth",
      "gender",
      "schoolName",
      "gradeClass",
      "parentName",
      "relationshipToChild",
      "contactNumber",
      "emailAddress",
      "emergencyContactPerson",
      "emergencyContactNumber",
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
      return;
    }

    const serviceID = "service_kq28sut";
    const templateID = "template_50dj1fp";
    const publicKey = "eYNYo9Lh7PXIe4p3Q";

    emailjs.sendForm(serviceID, templateID, e.target, publicKey).then(
      (result) => {
        console.log("Email sent successfully:", result.text);
        alert(
          "Thank you! Your enrollment application has been submitted successfully."
        );
        window.location.href = "/";
      },
      (error) => {
        console.error("Error sending email:", error.text);
        alert(
          "There was an error submitting your enrollment application. Please try again later."
        );
      }
    );
  };

  return (
    <>
      <Navbar />

      <div className="culturalfest-heading-main-box" style={{ margin: 'auto', textAlign: 'center', marginTop: '30px' }}>
        <h5 style={{ color: '#000' }}>LifeHisto.com</h5>
        <h1 className="culturalfest-home-heading-sizes" id="animate-charcter">Telangana State School Olympiad Games 2024</h1>
        <h1>Enrollment Application</h1>
      </div>

      <form className="culturalfest-form" onSubmit={handleSubmit}>
        <h2>Child's Information:</h2>

        <label>
          Full Name of Child:
          <input
            type="text"
            name="childName"
            value={formData.childName}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Date of Birth:
          <input
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Gender:
          <select
            className="culturalfest-gender-select"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
            required
          >
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </label>

        <label>
          School Name:
          <input
            type="text"
            name="schoolName"
            value={formData.schoolName}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Grade/Class:
          <input
            type="text"
            name="gradeClass"
            value={formData.gradeClass}
            onChange={handleChange}
            required
          />
        </label>

        <h2>Parent/Guardian Information:</h2>

        <label>
          Full Name of Parent/Guardian:
          <input
            type="text"
            name="parentName"
            value={formData.parentName}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Relationship to Child:
          <input
            type="text"
            name="relationshipToChild"
            value={formData.relationshipToChild}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Contact Number:
          <input
            type="tel"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Email Address:
          <input
            type="email"
            name="emailAddress"
            value={formData.emailAddress}
            onChange={handleChange}
            required
          />
        </label>

        <h2>Emergency Contact Information:</h2>

        <label>
          Emergency Contact Person:
          <input
            type="text"
            name="emergencyContactPerson"
            value={formData.emergencyContactPerson}
            onChange={handleChange}
            required
          />
        </label>

        <label>
          Emergency Contact Number:
          <input
            type="tel"
            name="emergencyContactNumber"
            value={formData.emergencyContactNumber}
            onChange={handleChange}
            required
          />
        </label>

        <h2>Medical Information:</h2>

        <label>
          Does the child have any allergies or medical conditions? If yes, please provide details:
          <textarea
            name="hasAllergiesOrMedicalConditions"
            value={formData.hasAllergiesOrMedicalConditions}
            onChange={handleChange}
          />
        </label>

        <label>
          Current Medications (if any):
          <input
            type="text"
            name="currentMedications"
            value={formData.currentMedications}
            onChange={handleChange}
          />
        </label>

        <div className="culturalfest-enroll-upi-div">
          <img
            className="culturalfest-enroll-upi-img"
            src="./images/lifehistopaymentqr.jpg"
            alt="Payment QR Code"
          />
          <p>PAYMENT QR CODE</p>
          <p className="culturalfest-enroll-upi-heading" style={{ color: "red" }}>
            <span style={{ color: '#fff' }}>NAME :</span> M/S.LIFEHISTO.COM PRIVATE LIMITED
          </p>
          <p>or</p>
          <h3 className="culturalfest-enroll-upi-h3">
            UPI Id:
            <span style={{ color: "red" }}> lifehisto@icici</span>
          </h3>
        </div>

        <p className="culturalfest-enroll-upi-note" style={{ backgroundColor: 'yellow', color: '#000', textAlign: 'center', fontWeight: 'bold' }}>
          NOTE: <br />“Please send your payment screenshot through WhatsApp to 9849856143 and receive confirmation."
        </p>

        <label>
          Enter Your Transaction Id:
          <input
            type="text"
            name="transactionId"
            onChange={handleChange}
            required
          />
        </label>

        <h2>Declaration:</h2>

        <label>
          <input
            type="checkbox"
            name="agreementConfirmed"
            checked={formData.agreementConfirmed}
            onChange={handleChange}
            required
          />
          I, the undersigned, hereby confirm that the information provided in this enrollment application is accurate and complete. I understand and agree to comply with the terms and conditions outlined by Learnorama Summer Camp.
        </label>

        <div className="culturalfest-submit-button-container">
          <button
            className="culturalfest-enroll-form-button"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>

      <Footer />
    </>
  );
};

export default OlympiadGamesForm;
