import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Navbar1 from "../Navbar1";
import { Grid, Stack } from "@mui/material";
import Marquee from "react-fast-marquee";
import Footer from "../footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import MarqueeComponent from "./MarqueeComponent";

const FAQ = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (faqId) => {
    setActiveDropdown(activeDropdown === faqId ? null : faqId);
  };

  const faqs = [
    {
      id: 1,
      question:
        "./images/faq--1.png",
      answer:
        "Giving our child celebrity status makes them more responsible and 90% of people intend to protect their character.",
    },
    {
      id: 2,
      question:
        "./images/faq--2.png",
      answer:
        "By developing a portfolio and sharing it with friends, family, and professionals, children receive appreciation and motivation to work hard towards their career growth. PORTFOLIOS ARE THE WAY TO REACH CORPORATE AND EDUCATION INSTITUTIONS FOR SPONSORSHIPS AND GOVERNMENTS GRANSTS.",
    },
    {
      id: 3,
      question:
        "./images/faq--3.png",
      answer:
        "LifeHisto is the first social media platform in India that helps in developing childrens portfolios. The subscription fee is 299.as we charge businesses that partner with us, such as national and international awards, record companies, sports academies, artists, cultural and educational equipment factories/industries, sports, cultural, talk shows, and seminar event organizers.",
    },
    {
      id: 4,
      question:
        "./images/faq--4.png",
      answer:
        "A Parents or legal guardians can create accounts for their children on LifeHisto, and they are the ones who have control over the content shared on the platform.",
    },
    {
      id: 5,
      question:
        "./images/faq--5.png",
      answer:
        "Yes, parents can share the LifeHisto profile of their child with potential employers, colleges, or anyone else who may be interested in learning more about the childs achievements and talents.",
    },
    {
      id: 6,
      question:
        "./images/faq--6.png",
      answer:
        "Yes, family members can access and contribute to a childs LifeHisto profile, allowing them to stay connected and engaged in the childs life journey.",
    },
    {
      id: 7,
      question:
        "./images/faq--7.png",
      answer:
        "LifeHisto provides a platform for parents and children to connect and share their experiences, creating a sense of community and support among families.",
    },
    {
      id: 8,
      question:
        "./images/faq--8.png",
      answer:
        "LifeHisto provides a way for parents to document and preserve their childs life journey, which can encourage them to be more present and involved in their childs life.",
    },
    {
      id: 9,
      question:
        "./images/faq--9.png",
      answer:
        "No, LifeHisto is not a regular social media site. We only take career growth-oriented content, and we connect the right requirements to the right providers without taking any commercials",
    },
    {
      id: 10,
      question:
        "./images/faq--10.png",
      answer:
        "LifeHisto has strict privacy policies and employs state-of-the-art security measures to protect childrens information from any unauthorized access or misuse.",
    },
    {
      id: 11,
      question:
        "./images/faq--11.png",
      answer:
        "No, LifeHisto is for all children regardless of their talents or interests. Its a platform for capturing and celebrating their life journey, and the milestones achieved along the way.",
    },
    {
      id: 12,
      question:
        "./images/faq--12.png",
      answer:
        "Parents or legal guardians can create accounts for their children on LifeHisto, and they are the ones who have control over the content shared on the platform.",
    },
  ];

  return (
    <>
      <Navbar1 />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto How can we help?"
        avatar="https://lifehisto-s3-bucket.s3.amazonaws.com/logo/web+-logo+copy.png"
      />

<ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        // viewBox="0 0 256 256"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #21207e, #21207e, #9787ff, #f055ed, #fc17fa)",
        }}
      />

<MarqueeComponent />

      <Grid container style={{ width: "100%", height: "auto" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center", padding: "15px" }}>
          <h1 id="animate-charcter" style={{ fontSize: "45px" }}>
            FAQ
          </h1>
          <p style={{ textAlign: "justify", padding: "30px" }}>
            Welcome to the FAQ page for LifeHisto! Here, we have compiled a list
            of frequently asked questions to help address any queries you may
            have about our services and offerings. If you don't find the answer
            you're looking for, please feel free to contact our support team for
            further assistance.
          </p>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="faq-mbox-2">
          <img src="./images/faq-main-gif.gif" alt="faq-main-gif" />
        </Grid>
      </Grid>

      <Typography
        style={{
          margin: "auto",
          padding: "15px",
          textAlign: "center",
        }}>
        <h1 id="animate-charcter" style={{ fontSize: "48px" }}>
          Most Frequently Asked Questions
        </h1>
      </Typography>

      <div className="faq-container">
      {/* <h2>FAQs for LifeHisto</h2> */}
      <div className="faq-item">
        <h3>1. <em>What is LifeHisto?</em></h3>
        <p>LifeHisto is a digital portfolio service that helps parents document and showcase their child's growth and achievements from birth onward.</p>
      </div>

      <div className="faq-item">
        <h3>2. <em>How does LifeHisto work?</em></h3>
        <p>Parents can upload photos, videos, and documents to create a comprehensive digital portfolio of their child's milestones, achievements, and memorable moments.</p>
      </div>

      <div className="faq-item">
        <h3>3. <em>What kind of content can I include in my child’s portfolio?</em></h3>
        <p>You can include photos, videos, school reports, artwork, certificates, and any other important documents that reflect your child’s journey.</p>
      </div>

      <div className="faq-item">
        <h3>4. <em>Is my child's information safe?</em></h3>
        <p>Yes, we prioritize your child’s privacy and security. All data is encrypted, and only you control who can access your child’s portfolio.</p>
      </div>

      <div className="faq-item">
        <h3>5. <em>How can I share my child's portfolio with family and friends?</em></h3>
        <p>You can share the portfolio via secure links or invite family and friends to view specific parts of the portfolio.</p>
      </div>

      <div className="faq-item">
        <h3>6. <em>Can I access the portfolio on multiple devices?</em></h3>
        <p>Yes, you can access your child’s portfolio from any device with internet access.</p>
      </div>

      <div className="faq-item">
        <h3>7. <em>Is there a cost for maintaining the portfolio?</em></h3>
        <p>Yes, LifeHisto charges a maintenance fee. You can find detailed pricing information on our website.</p>
      </div>

      <div className="faq-item">
        <h3>8. <em>How do I start a portfolio for my child?</em></h3>
        <p>Sign up on our website, create a profile for your child, and start uploading content to their digital portfolio.</p>
      </div>

      <div className="faq-item">
        <h3>9. <em>Can I update or edit the portfolio after creating it?</em></h3>
        <p>Yes, you can continuously update and edit the portfolio to include new milestones and achievements.</p>
      </div>

      <div className="faq-item">
        <h3>10. <em>What happens if I stop paying the maintenance fee?</em></h3>
        <p>Your access to the portfolio might be limited. Please refer to our terms and conditions for more details.</p>
      </div>
    </div>

      <Stack
        style={{
          width: "80%",
          margin: "auto",
          marginTop: "100px",
          marginBottom: "100px",
        }}>
        <Grid
          container
          style={{
            width: "100%",
            height: "auto",
            margin: "auto",
            textAlign: "center",
          }}>
          <Grid item sm={12} md={4} lg={4} xl={4}>
            <div
              style={{
                width: "80%",
                height: "auto",
                margin: "auto",
                textAlign: "center",
              }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src="./images/How to manage.png"
                alt="How to manage.png"
              />
            </div>
          </Grid>

          <Grid item sm={12} md={4} lg={4} xl={4}>
            <div
              style={{
                width: "80%",
                height: "auto",
                margin: "auto",
                textAlign: "center",
              }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src="./images/What to do.png"
                alt="What to do.png"
              />
            </div>
          </Grid>
          <Grid item sm={12} md={4} lg={4} xl={4}>
            <div
              style={{
                width: "80%",
                height: "auto",
                margin: "auto",
                textAlign: "center",
              }}>
              <img
                style={{ width: "100%", height: "100%" }}
                src="./images/We're to reach.png"
                alt="We're to reach.png"
              />
            </div>
          </Grid>
        </Grid>
      </Stack>

      <Footer />
    </>
  );
};

export default FAQ;
