import React from "react";
import Navbar1 from "../Navbar1";
import { Typography } from "@mui/material";
import Footer from "../footer";

const RefundCancellationPolicy = () => {
  return (
    <>
      <Navbar1 />
      <Typography
        style={{
          margin: "auto",
          textAlign: "center",
          fontSize: "36px",
          fontWeight: "bolder",
          padding: "25px",
        }}>
        Refund Cancellation Policy
      </Typography>
      <Typography style={{
        width:'70%',
          margin: "auto",
          textAlign: "justify",
          alignItems:'center',
          justifyContent:'center',
          
        }}>
      Thank you for your interest in our products and services. We would like to inform you about our refund and cancellation policy for our Rs. 299/- service package.

Please note that the Rs. 299/- package is a non-refundable service and once purchased, cannot be cancelled or refunded. This is because the package is a one-time service that is immediately activated upon purchase, and cannot be reversed or undone.

We encourage all customers to carefully review the package details and benefits before making a purchase to ensure that the package meets their needs and requirements. If you have any questions or concerns about the package, please do not hesitate to contact our customer service team for assistance.

In the event of any technical issues or errors, we will do our best to provide support and assistance to resolve the issue as quickly as possible. However, please note that we cannot offer refunds or cancellations for the Rs. 299/- package.

We appreciate your understanding and cooperation regarding our refund and cancellation policy for the Rs. 299/- service package. If you have any further questions or concerns, please feel free to contact us and we will be happy to assist you.
      </Typography>

      <Footer style={{bottom: 0}} />
    </>
  );
};

export default RefundCancellationPolicy;
