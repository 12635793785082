import React from "react";
import { Typography } from "@mui/material";
import Navbar1 from "../Navbar1";
import Footer from "../footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar1 />
      
      <Typography
        style={{
          margin: "auto",
          textAlign: "center",
          fontSize: "36px",
          fontWeight: "bolder",
          padding: "25px",
        }}>
        Privacy Policy
      </Typography>

      <Typography style={{width:'80%', margin:'auto',textAlign:'justify'}}>
        At LifeHisto, we are committed to protecting the privacy of our users,
        especially children. This privacy policy explains how we collect, use,
        and protect personal information provided by our users. By using our
        website, you consent to the terms of this privacy policy. Information We
        Collect: We collect personal information from our users such as name,
        age, gender, and email address. Additionally, we may collect data about
        a child’s education, interests, and hobbies to help build their
        portfolio. We do not collect any personally identifiable information
        from children under the age of 13 without the consent of their parent or
        guardian. Use of Information: We use the information collected to
        provide and improve our services, personalize user experience, and
        communicate with our users. We may also use the information to send
        promotional offers, newsletters, and other marketing communications.
        Protection of Information: We take appropriate measures to ensure the
        security of our user’s personal information. We use industry-standard
        security technologies and procedures to protect the confidentiality,
        integrity, and availability of the data. We do not share, sell or rent
        any personal information collected with third parties. Parental Rights:
        Parents or guardians have the right to review, delete, or modify their
        child’s personal information collected by our website. Parents can also
        request that their child’s personal information is not shared with third
        parties. Parents may contact us using the information below to exercise
        these rights. Changes to this Policy: We may update this privacy policy
        from time to time. Users will be notified of any changes to the policy
        via email or by a notice on our website. Contact Us: If you have any
        questions or concerns about this privacy policy or our practices, please
        contact us at
      </Typography>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
