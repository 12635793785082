import React from 'react';
import Marquee from 'react-fast-marquee';

function MarqueeComponent() {
  return (
    <Marquee
      style={{
        backgroundColor: "#fff",
        color: "#fc17fa",
        padding: "12px",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
      }}
      gradient={false}
      speed={40}
    >
      <h4>
        <span style={{ marginRight: "100px" }}>
          "The best chronicle life book"
          <span style={{ margin: "10px" }}>Subscription at ₹2999/-</span>
          Customer Care: 9293225555 Upcoming Events: TELANGANA STATE CULTURAL FEST FOR SCHOOL CHILDREN, TELANGANA STATE SCHOOL OLYMPIAD GAMES 2024
        </span>
        <span style={{ marginRight: "100px" }}>
          "The best chronicle life book"
          <span style={{ margin: "10px" }}>Subscription at ₹2999/-</span>
          Customer Care: 9293225555 Upcoming Events: TELANGANA STATE CULTURAL FEST FOR SCHOOL CHILDREN, TELANGANA STATE SCHOOL OLYMPIAD GAMES 2024
        </span>
        <span style={{ marginRight: "100px" }}>
          "The best chronicle life book"
          <span style={{ margin: "10px" }}>Subscription at ₹2999/-</span>
          Customer Care: 9293225555 Upcoming Events: TELANGANA STATE CULTURAL FEST FOR SCHOOL CHILDREN, TELANGANA STATE SCHOOL OLYMPIAD GAMES 2024
        </span>
        <span style={{ marginRight: "100px" }}>
          "The best chronicle life book"
          <span style={{ margin: "10px" }}>Subscription at ₹2999/-</span>
          Customer Care: 9293225555 Upcoming Events: TELANGANA STATE CULTURAL FEST FOR SCHOOL CHILDREN, TELANGANA STATE SCHOOL OLYMPIAD GAMES 2024
        </span>
        <span style={{ marginRight: "100px" }}>
          "The best chronicle life book"
          <span style={{ margin: "10px" }}>Subscription at ₹2999/-</span>
          Customer Care: 9293225555 Upcoming Events: TELANGANA STATE CULTURAL FEST FOR SCHOOL CHILDREN, TELANGANA STATE SCHOOL OLYMPIAD GAMES 2024
        </span>
        <span style={{ marginRight: "100px" }}>
          "The best chronicle life book"
          <span style={{ margin: "10px" }}>Subscription at ₹2999/-</span>
          Customer Care: 9293225555 Upcoming Events: TELANGANA STATE CULTURAL FEST FOR SCHOOL CHILDREN, TELANGANA STATE SCHOOL OLYMPIAD GAMES 2024
        </span>
        <span style={{ marginRight: "100px" }}>
          "The best chronicle life book"
          <span style={{ margin: "10px" }}>Subscription at ₹2999/-</span>
          Customer Care: 9293225555 Upcoming Events: TELANGANA STATE CULTURAL FEST FOR SCHOOL CHILDREN, TELANGANA STATE SCHOOL OLYMPIAD GAMES 2024
        </span>
        <span style={{ marginRight: "100px" }}>
          "The best chronicle life book"
          <span style={{ margin: "10px" }}>Subscription at ₹2999/-</span>
          Customer Care: 9293225555 Upcoming Events: TELANGANA STATE CULTURAL FEST FOR SCHOOL CHILDREN, TELANGANA STATE SCHOOL OLYMPIAD GAMES 2024
        </span>
      </h4>
    </Marquee>
  );
}

export default MarqueeComponent;
