import React from 'react';
import './CulturalFest.css'; // Ensure you create this CSS file with styles
import Navbar from '../Navbar1';
import Footer from '../footer';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const CulturalFest = () => {
  return (
    <>
    <Navbar />

  

    <div className="CulturalFest-container">

    <div className='cult-img-div'>
      <img src='./images/cul1.png' />
      </div>

      <header className="header">
      <h1 className="title">Telangana State Cultural & Academic Fest for School Children 2024</h1>
      </header>

      
      {/* <hr /> */}
      <div className="content">
        <p><em>Welcome to the Telangana State Cultural & Academic Fest for School Children 2024!</em></p>
        <p>Join us from September 29-30, 2024, at GD Goenka School, LB Nagar, Hyderabad, for a vibrant two-day celebration of arts, culture, and academics. Sponsored by Learnorama Technologies Pvt Ltd and organized by Lifehisto.com Pvt Ltd, this event provides a platform for students to showcase their talents in various cultural and academic activities, compete for fantastic prizes, and earn recognition.</p>
      </div>

      <div className="event-section">
        <h2>Event Descriptions and Concepts</h2>
        {/* <hr /> */}

        <h1 style={{margin:'auto',textAlign:'center'}}>Event Overview</h1>
      <div style={{textAlign: 'center', margin: '20px 0'}}>
        <a 
          href="https://learnoramabackend.learnorama.in/pdfs/CULTURAL.pdf" 
          download 
          // className="pdf-download-button"
          class="btn-hover color-3"
          //  className="button-2"
          style={{
            display: 'inline-block',
            padding: '10px 20px',
            backgroundColor: '#004AAD',
            color: '#fff',
            textDecoration: 'none',
            borderRadius: '5px',
            fontSize: '16px',
            fontWeight: 'bold'
          }}
        >
          Download PDF
        </a>
      </div>

      <Link to="/enrollments" style={{ textDecoration: "none" }}>
          <Button className="btn-hover color-3" variant="contained" color="primary">
          Enroll Now
          </Button>
        </Link>

        {/* Dance Competitions */}
        <div className="event">
          <h3>Dance Competitions</h3>
          <div className="details">
            <p><strong>Solo Dance:</strong></p>
            <ul>
              <li>Categories: Under 10 years and Under 15 years</li>
              <li>Rounds: Semi-Classical, Western, and Folk</li>
              <li>Concept: Solo dance performances judged on technique, expression, and choreography across three rounds.</li>
              <li>Entry Fee: ₹1500 per participant</li>
            </ul>
            <p><strong>Group Dance:</strong></p>
            <ul>
              <li>Categories: Under 10 years and Under 15 years</li>
              <li>Rounds: Semi-Classical, Western, and Classical</li>
              <li>Concept: Group dance performances (3 to 10 participants) judged on synchronization, creativity, and cultural representation across three rounds.</li>
              <li>Entry Fee: ₹3000 per team</li>
            </ul>
          </div>
        </div>

        {/* Fashion Show */}
        <div className="event">
          <h3>Fashion Show</h3>
          <div className="details">
            <p><strong>Solo Fashion Show:</strong></p>
            <ul>
              <li>Categories: Under 10 years and Under 15 years</li>
              <li>Rounds: Ethnic Wear, Corporate Wear, and Party Wear</li>
              <li>Concept: Participants showcase their attire in three different categories. Judged on creativity, presentation, and adherence to the theme.</li>
              <li>Entry Fee: ₹1500 per participant</li>
            </ul>
          </div>
        </div>

        {/* Art Competitions */}
        <div className="event">
          <h3>Art Competitions</h3>
          <div className="details">
            <p><strong>Painting:</strong></p>
            <ul>
              <li>Categories: Under 10 years and Under 15 years</li>
              <li>Rounds: Preliminary and Final</li>
              <li>Concept: Participants create paintings based on given themes. Judged on creativity, technique, and adherence to the theme.</li>
              <li>Entry Fee: ₹1000 per participant</li>
            </ul>
            <p><strong>Sketching:</strong></p>
            <ul>
              <li>Categories: Under 10 years and Under 15 years</li>
              <li>Rounds: Preliminary and Final</li>
              <li>Concept: Participants create sketches based on given themes. Judged on detail, technique, and creativity.</li>
              <li>Entry Fee: ₹1000 per participant</li>
            </ul>
            <p><strong>Craft:</strong></p>
            <ul>
              <li>Categories: Under 10 years and Under 15 years</li>
              <li>Rounds: Preliminary and Final</li>
              <li>Concept: Participants create craft items based on given themes. Judged on creativity, craftsmanship, and originality.</li>
              <li>Entry Fee: ₹1000 per participant</li>
            </ul>
          </div>
        </div>

        {/* Academic Competitions */}
        <div className="event">
          <h3>Academic Competitions</h3>
          <div className="details">
            <p><strong>Paper Presentation on Artificial Intelligence:</strong></p>
            <ul>
              <li>Categories: Under 10 years and Under 15 years</li>
              <li>Rounds: Single Round</li>
              <li>Concept: Participants present research papers on Artificial Intelligence. Judged on design, presentation skills, and Q&A session.</li>
              <li>Entry Fee: ₹1500 per participant</li>
            </ul>
            <p><strong>Debate on Artificial Intelligence:</strong></p>
            <ul>
              <li>Categories: Under 10 years and Under 15 years</li>
              <li>Rounds: Preliminary and Final</li>
              <li>Concept: Participants engage in debates on topics related to Artificial Intelligence. Judged on arguments, presentation, and rebuttals.</li>
              <li>Entry Fee: ₹1500 per participant</li>
            </ul>
            <p><strong>Quiz:</strong></p>
            <ul>
              <li>Categories: Under 10 years and Under 15 years</li>
              <li>Rounds: Preliminary and Final</li>
              <li>Concept: Teams compete in a quiz covering general knowledge, current affairs, Artificial Intelligence, and sports. Judged on accuracy, speed, and teamwork.</li>
              <li>Entry Fee: ₹3000 per team (3-5 participants)</li>
            </ul>
          </div>
        </div>

      </div>

      <div className="prizes-section">
        <h2>Prizes and Awards</h2>
        {/* <hr /> */}

        {/* Prizes and Awards */}
        <div className="prizes">
          <p><strong>Individual Events:</strong></p>
          <ul>
            <li>First Place: ₹10,000 Cash Prize, Gold Medal, and Merit Certificate</li>
            <li>Second Place: ₹7,000 Cash Prize, Silver Medal, and Merit Certificate</li>
            <li>Third Place: ₹5,000 Cash Prize, Bronze Medal, and Merit Certificate</li>
          </ul>

          <p><strong>Group Events:</strong></p>
          <ul>
            <li>First Place: ₹15,000 Cash Prize (₹3,000 per participant), Gold Medal, and Merit Certificates</li>
            <li>Second Place: ₹10,000 Cash Prize (₹2,000 per participant), Silver Medal, and Merit Certificates</li>
            <li>Third Place: ₹7,000 Cash Prize (₹1,000 per participant), Bronze Medal, and Merit Certificates</li>
          </ul>

          <p><strong>Gifts and Gift Coupons:</strong></p>
          <p>Gifts worth ₹10,00,000 will be distributed among winners as follows:</p>
          <ul>
            <li><strong>Individual Events:</strong> Gift worth ₹50,000 (or equivalent in gift coupons) for first place, ₹30,000 for second place, and ₹20,000 for third place.</li>
            <li><strong>Group Events:</strong> Gift worth ₹75,000 (₹15,000 per participant, or equivalent in gift coupons) for first place, ₹50,000 (₹10,000 per participant) for second place, and ₹35,000 (₹5,000 per participant) for third place.</li>
          </ul>
        </div>
      </div>

      <div className="rules-section">
        <h2>Rules and Regulations</h2>
        {/* <hr /> */}

        {/* Rules and Regulations */}
        <div className="rules">
          <p><strong>Eligibility:</strong></p>
          <ul>
            <li>Participants must be enrolled in a school in Telangana.</li>
            <li>Age categories: Under 10 years and Under 15 years. Age will be verified based on the participant's school records.</li>
          </ul>

          <p><strong>Entries:</strong></p>
          <ul>
            <li>No restrictions on the number of entries from each school.</li>
            <li>Participants can compete in a maximum of two events.</li>
          </ul>

          <p><strong>Attire:</strong></p>
          <ul>
            <li>Participants can wear appropriate attire for their respective events. There is no requirement for school cultural wear.</li>
          </ul>

          <p><strong>Conduct:</strong></p>
          <ul>
            <li>Participants must maintain respect towards officials and fellow participants.</li>
            <li>Any form of cheating or misconduct will result in immediate disqualification.</li>
          </ul>

          <p><strong>Registration:</strong></p>
          <ul>
            <li>All entry fees must be paid online at <a href="http://www.lifehisto.com">www.lifehisto.com</a> or via Demand Draft (DD) in favor of Lifehisto Pvt Ltd.</li>
            <li>The DD and participation list must be couriered to GD Goenka School on or before September 15, 2024.</li>
          </ul>

          <p><strong>Safety and Liability:</strong></p>
          <ul>
            <li>Participants must follow safety protocols for each event.</li>
            <li>Organizers are not liable for any injuries sustained during the events.</li>
          </ul>

          <p><strong>Judging and Decisions:</strong></p>
          <ul>
            <li>All decisions made by the judges are final.</li>
            <li>Protests or appeals must be submitted in writing within 30 minutes of the event’s conclusion.</li>
          </ul>
        </div>
      </div>

      <div className="committee-section">
        <h2>Committee Members</h2>
        {/* <hr /> */}

        {/* Committee Members */}
        <div className="committee">
          <p><strong>Organizing Secretary:</strong></p>
          <p>Dr. Padala Karthik Reddy</p>

          <p><strong>Event Coordinator:</strong></p>
          <p>Harish Pampana</p>

          <p><strong>Arbitration Incharge:</strong></p>
          <p>Mohmad Saleem (BCCI and FIDE Qualified Umpire and Arbiter)</p>

          <p><strong>Boys Incharge:</strong></p>
          <p>Basker Nathan</p>

          <p><strong>Girls Incharge:</strong></p>
          <p>Sonia Patil</p>

          <p><strong>Accommodation and Food Incharge:</strong></p>
          <p>Arika Reddy</p>
        </div>
      </div>

      <div className="sponsors-section">
        <h2>Sponsors and Partners</h2>
        {/* <hr /> */}

        {/* Sponsors and Partners */}
        <div className="sponsors">
          <p><strong>Title Sponsor:</strong> Pending</p>
          <p><strong>Event Sponsor:</strong> Learnorama Technologies Private Limited</p>
          <p><strong>Gift Cards Partner:</strong> Amazon</p>
          <p><strong>Gift Coupons:</strong> VKR Ventures</p>
          <p><strong>Gifting Partner:</strong> Fevicryl Company</p>
          <p><strong>Electronic Media Partner:</strong> Pending</p>
          <p><strong>Print Media Partner:</strong> Pending</p>
        </div>
      </div>

      <div className="sponsorship-section">
        <h2>Additional Sponsorship Opportunities</h2>
        {/* <hr /> */}

        {/* Additional Sponsorship Opportunities */}
        <div className="sponsorship">
          <p><strong>Print Partner:</strong> Opportunity to sponsor all printed materials for the event.</p>
          <p><strong>Co-Sponsorship:</strong> Multiple opportunities for companies to co-sponsor the event.</p>
          <p><strong>Cultural Equipment Sponsorship:</strong> Opportunity to provide equipment for the events.</p>
          <p><strong>Hospitality Sponsor:</strong> Opportunity to sponsor accommodation and food services for participants and coaches.</p>
          <p><strong>Technology Sponsor:</strong> Provide technological support and equipment for the event.</p>
          <p><strong>Merchandise Sponsor:</strong> Opportunity to supply event merchandise such as T-shirts, caps, and other memorabilia.</p>
          <p><strong>Health and Wellness Sponsor:</strong> Provide medical and wellness services during the event.</p>
        </div>
      </div>

      <div className="contact-section">
        <h2>Contact Information</h2>
        {/* <hr /> */}

        {/* Contact Information */}
        <div className="contact">
          <p><strong>Phone:</strong> 9293225555</p>
          <p><strong>Email:</strong> info@lifehisto.com</p>
          <p><strong>Address:</strong> GD Goenka School, LB Nagar, Hyderabad</p>
          <p><strong>Website:</strong> <a href="http://www.lifehisto.com">www.lifehisto.com</a></p>
        </div>
      </div>
    </div>

    <Footer />
    </>
  );
};

export default CulturalFest;
